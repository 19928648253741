'use client';

import { useEffect, useState } from 'react';
import { pushProductClicked, pushAddToCart } from '@theme/utils/gtm';
import { useInView } from 'react-intersection-observer';
import useFavButton from '../../hooks/use-fav-button';
import { Product } from '@akinon/next/types';
import { Image } from '@akinon/next/components/image';
import {
  Price,
  Link,
  Icon,
  LoaderSpinner,
  Modal,
  Button,
  BottomSheet
} from '@theme/components';
import clsx from 'clsx';
import { Trans } from '@akinon/next/components/trans';
import { useAddProductToBasket } from '../../hooks';
import { useLocalization, useMediaQuery, useRouter } from '@akinon/next/hooks';
import { ProductStockAlertModal } from '../product/stock-alert-modal';
import { ROUTES } from '@theme/routes';
import { useDeleteWishlistProductMutation } from '@theme/rtk/api/wishlist';
import { useGetProductByParamsQuery } from '@akinon/next/data/client/product';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import settings from '@theme/settings';

interface Props {
  product: Product;
  width?: number;
  height?: number;
  index: number;
  contentClassName?: string;
  className?: string;
  removeGiftList?: number;
  fetchPriority?: 'high' | 'low';
  showBadges?: boolean;
  isVertical?: boolean;
  imageLoading?: 'eager' | 'lazy';
  refetch?: () => void;
  setViewedProducts?: React.Dispatch<React.SetStateAction<Product[]>>;
}

export const ProductItem = (props: Props) => {
  const { t, locale, currency } = useLocalization();
  const {
    product,
    width,
    height,
    index,
    contentClassName,
    className,
    removeGiftList,
    fetchPriority,
    imageLoading,
    showBadges = true,
    isVertical = false,
    refetch,
    setViewedProducts
  } = props;

  const [deleteWishlistProduct] = useDeleteWishlistProductMutation();

  const [viewed, setViewed] = useState(false);
  const { FavButton } = useFavButton(product.pk, product);
  const { ref: productItemRef, inView } = useInView();
  const image_url = product.productimage_set[0]?.image;
  const absolute_url = product.absolute_url;
  const product_name = product.name;
  const retail_price = product.retail_price;
  const price = product.price;
  const stock = product.stock;
  const [loading, setLoading] = useState(false);

  const [quickShopOpen, setQuickShopOpen] = useState(false);
  const [selectedProduct, setProduct] = useState(product);
  const [colorVariants, setColorVariants] = useState([]);
  const [sizeVariants, setSizeVariants] = useState([]);
  const [lengthVariants, setLengthVariants] = useState([]);
  const [widthVariants, setWidthVariants] = useState([]);
  const [heightVariants, setHeightVariants] = useState([]);
  const [variantCount, setVariantCount] = useState(0);
  const [quickShopProduct, setQuickShopProduct] = useState(null);
  const { data: productBadges } = useGetWidgetQuery('product-badges');

  const badges = productBadges?.attributes?.badges.filter((badge) => {
    if (product?.attributes?.yeni_urun) {
      return badge?.value?.attribute === 'newproduct';
    }

    if (product?.attributes?.integration_urun_grubu === 'MB') {
      return badge?.value?.attribute === 'boutiqueproduct';
    }

    if (product?.attributes?.el_yapimi == true) {
      return badge?.value?.attribute === 'el_yapimi';
    }

    if (product?.attributes?.cok_sevdik == true) {
      return badge?.value?.attribute === 'cok_sevdik';
    }

    if (product?.attributes?.online_ozel == true) {
      return badge?.value?.attribute === 'online_ozel';
    }
  });

  const [
    addProduct,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { isLoading: isAddToCartLoading },
    showBottomSheet,
    setShowBottomSheet,
    errorMessage
  ] = useAddProductToBasket();
  const [productError, setProductError] = useState(null);
  const discount = Math.round(
    100 - (parseInt(price) / parseInt(retail_price)) * 100
  );
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const router = useRouter();

  useEffect(() => {
    if (!viewed && inView) {
      setViewed(true);
      setViewedProducts &&
        setViewedProducts((prev: Product[]) => [...prev, product]);
    }
  }, [inView, product, setViewedProducts, viewed]);

  const [showStockAlertModal, setShowStockAlertModal] =
    useState<boolean>(false);

  const removeFromGiftList = async (pk) => {
    await deleteWishlistProduct(pk);
    refetch();
  };

  const pk = selectedProduct.pk;
  const attributes = product?.extra_data?.variants;
  let colorCount = 0;

  if (attributes) {
    attributes?.forEach((attribute) => {
      if (attribute?.attribute_key === 'integration_renk') {
        colorCount = attribute?.options?.length;
      }
    });
  }

  const [selectedOptions, setSelectedOptions] = useState(() => {
    const renkAttribute = attributes?.['integration_renk'];
    return renkAttribute ? { ['integration_renk']: renkAttribute } : {};
  });

  const { data } = useGetProductByParamsQuery(
    quickShopOpen ? { pk, ...selectedOptions } : skipToken
  );

  useEffect(() => {
    if (data) {
      const variantCount = data?.variants?.length;

      const colorVariants =
        data?.variants.find(
          (variant) => variant.attribute_key === 'integration_renk'
        )?.options ?? [];

      const sizeVariants =
        data?.variants.find(
          (variant) => variant.attribute_key === 'integration_hacim'
        )?.options ?? [];

      const lengthVariants =
        data?.variants.find(
          (variant) => variant.attribute_key === 'integration_uzunluk'
        )?.options ?? [];

      const widthVariants =
        data?.variants.find(
          (variant) => variant.attribute_key === 'integration_genislik'
        )?.options ?? [];

      const heightVariants =
        data?.variants.find(
          (variant) => variant.attribute_key === 'integration_yukseklik'
        )?.options ?? [];

      setVariantCount(variantCount);
      setColorVariants(colorVariants);
      setSizeVariants(sizeVariants);
      setLengthVariants(lengthVariants);
      setWidthVariants(widthVariants);
      setHeightVariants(heightVariants);
      setQuickShopProduct(data);
      setLoading(false);
    }
  }, [data]);

  const selectAttribute = (attribute_key, value) => {
    const newSelectedOptions = { ...selectedOptions };

    if (attribute_key === 'integration_renk') {
      delete newSelectedOptions['integration_hacim'];
      delete newSelectedOptions['integration_uzunluk'];
      delete newSelectedOptions['integration_genislik'];
      delete newSelectedOptions['integration_yukseklik'];
    }

    newSelectedOptions[attribute_key] = value;
    setSelectedOptions(newSelectedOptions);
  };

  const addToBasket = async () => {
    setLoading(true);

    try {
      await addProduct({
        product: selectedProduct.pk,
        quantity: 1,
        attributes: {},
        sku: selectedProduct.sku
      });
    } catch (error) {
      setProductError(
        error?.data?.non_field_errors ||
          Object.keys(error?.data).map(
            (key) => `${key}: ${error?.data[key].join(', ')}`
          )
      );
    } finally {
      setLoading(false);
      setQuickShopOpen(null);
    }
  };

  const handleProductClick = async () => {
    if (!data && !quickShopOpen) {
      setLoading(true);
    } else if (data && quickShopOpen) {
      setLoading(false);
    }

    if (!data) {
      setLoading(true);

      try {
        const currentApiValue = settings?.localization?.locales?.find(
          (l) => l?.value === locale
        )?.apiValue;

        const productData = await fetch(`/api/client/product/${pk}`, {
          headers: {
            'Accept-Language': currentApiValue ?? 'tr-tr',
            'x-currency': currency
          }
        }).then((response) => response.json());

        const hasSelectableVariants = productData?.variants?.some(
          (variant) =>
            variant.options.length > 1 &&
            variant.options.some((option) => option.is_selectable)
        );

        if (!hasSelectableVariants) {
          await addToBasket();
          pushAddToCart(productData?.product, 1);

          return;
        }
      } finally {
        setLoading(false);
      }
    }

    if (!quickShopOpen) {
      setQuickShopOpen(true);
    } else if (quickShopProduct && quickShopOpen) {
      const result = await variantsSelectionCheck();

      result && addToBasket() && pushAddToCart(quickShopProduct?.product, 1);
    } else {
      setQuickShopOpen(null);
    }
  };

  const variantsSelectionCheck = () => {
    const unselectedVariant = data.variants.find((variant) =>
      variant.options.every((opt) => !opt.is_selected)
    );

    if (unselectedVariant) {
      setProductError(() => (
        <Trans
          i18nKey="product.please_select_variant"
          components={{
            VariantName: <span>{unselectedVariant.attribute_name}</span>
          }}
        />
      ));

      return false;
    }

    setProductError(null);
    return true;
  };

  useEffect(() => {
    if (productError) {
      setTimeout(() => {
        setProductError(null);
      }, 30000);
    }
  }, [productError]);

  return (
    <div
      ref={productItemRef}
      className={clsx(
        'flex flex-col justify-start gap-2 text-left text-sm',
        className
      )}
      data-testid="product-box"
    >
      <Modal
        portalId="product-list-quick-shop-modal"
        title={t('product.quick_shop.quick_add_to_cart')}
        open={quickShopOpen && isMobile}
        className="bottom-0 left-0 top-auto max-h-[90vh] w-full translate-x-0 translate-y-0 overflow-y-auto sm:w-[28rem]"
        classNameHeader="px-4"
        setOpen={() => setQuickShopOpen && setQuickShopOpen(null)}
        quickShop={true}
      >
        {quickShopOpen && (
          <div className="h-auto w-full bg-white px-4 pb-4 pt-6">
            {colorVariants.length > 0 && (
              <div className="mb-4 flex flex-col gap-1 lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.color')}
                </span>
                <div className="flex flex-wrap gap-3">
                  {colorVariants.map(
                    (color, index) =>
                      color.product.productimage_set[0]?.image && (
                        <button
                          className={clsx(
                            'relative cursor-pointer border text-sm',
                            !color.is_selectable &&
                              '!cursor-not-allowed opacity-50 before:absolute before:left-[-14px] before:top-1/2 before:z-[1] before:block before:h-[1px] before:w-[162%] before:rotate-[-52deg] before:bg-black/80 before:content-[""]',
                            color.is_selected
                              ? '!border-primary'
                              : 'border-lightBlue',
                            color.product.productimage_set[0]?.image
                              ? 'h-[64px] w-[48px] p-0'
                              : 'px-3 py-2'
                          )}
                          key={index}
                          onClick={() => {
                            if (!color?.is_selectable) return;
                            selectAttribute(
                              'integration_renk',
                              color.product.attributes['integration_renk']
                            );
                            setProduct(color.product);
                          }}
                          title={color?.product?.attributes?.filterable_renk}
                        >
                          {color.product.productimage_set[0]?.image ? (
                            <Image
                              src={color.product.productimage_set[0]?.image}
                              alt={color.label}
                              width={48}
                              height={64}
                              className="flex !h-full w-auto justify-center"
                            />
                          ) : (
                            color.label
                          )}
                        </button>
                      )
                  )}
                </div>
              </div>
            )}

            {sizeVariants.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.size')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {sizeVariants.map((size, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !size.is_selectable && 'cursor-not-allowed opacity-50',
                        size.is_selected
                          ? '!border-primary'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!size?.is_selectable) return;
                        selectAttribute(
                          'integration_hacim',
                          size.product.attributes['integration_hacim']
                        );
                        setProduct(size.product);
                      }}
                      disabled={!size.is_selectable}
                    >
                      {size.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {lengthVariants.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.length')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {lengthVariants.map((length, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !length.is_selectable &&
                          'cursor-not-allowed opacity-50',
                        length.is_selected
                          ? '!border-primary'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!length?.is_selectable) return;
                        selectAttribute(
                          'integration_uzunluk',
                          length.product.attributes['integration_uzunluk']
                        );
                        setProduct(length.product);
                      }}
                      disabled={!length.is_selectable}
                    >
                      {length.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {heightVariants.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.height')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {heightVariants.map((height, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !height.is_selectable &&
                          'cursor-not-allowed opacity-50',
                        height.is_selected
                          ? '!border-primary'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!height?.is_selectable) return;
                        selectAttribute(
                          'integration_yukseklik',
                          height.product.attributes['integration_yukseklik']
                        );
                        setProduct(height.product);
                      }}
                      disabled={!height.is_selectable}
                    >
                      {height.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {widthVariants.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.width')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {widthVariants.map((width, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !width.is_selectable && 'cursor-not-allowed opacity-50',
                        width.is_selected
                          ? '!border-primary'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!width?.is_selectable) return;

                        selectAttribute(
                          'integration_genislik',
                          width.product.attributes['integration_genislik']
                        );
                        setProduct(width.product);
                      }}
                      disabled={!width.is_selectable}
                    >
                      {width.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="mt-[25px] flex w-full items-end justify-between">
              <div className="flex flex-col justify-start">
                {parseFloat(retail_price) > parseFloat(price) && (
                  <div className="-ml-[6px] flex items-center">
                    <Icon
                      name={'down-small-full'}
                      className={clsx('text-xl leading-4 text-error')}
                    />
                    <span className="mr-3 text-xs text-error underline">
                      %{discount}{' '}
                    </span>
                    <span className="text-sm text-gray-500 line-through md:text-sm">
                      <Price
                        value={retail_price}
                        className="mr-3 font-normal line-through"
                      />
                    </span>
                  </div>
                )}
                <Price
                  className="text-xl font-semibold"
                  value={price}
                  data-testid="product-price"
                />
              </div>

              {!product.in_stock ? (
                <Button onClick={() => setShowStockAlertModal(true)}>
                  {t('product.add_stock_alert')}
                </Button>
              ) : (
                <div
                  className={clsx(
                    'flex items-center justify-start gap-[14px] bg-primary text-white',
                    !loading ? 'p-3' : 'h-12 w-12'
                  )}
                  onClick={() => handleProductClick()}
                >
                  {loading ? (
                    <LoaderSpinner className="h-5 w-5 border-white " />
                  ) : (
                    <>
                      <Icon name="plus" />
                      <span className="text-base">
                        {t('product.quick_shop.add_to_cart')}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
      <div
        className={clsx(
          'relative max-h-[16.75rem] md:max-h-[28.125rem]',
          isVertical &&
            'block max-w-[179px] justify-center lg:max-h-full lg:max-w-full xl:!max-h-[200px] xl:max-w-[296px]'
        )}
      >
        <Link
          href={absolute_url}
          onClick={() => pushProductClicked(product)}
          className="w-full"
        >
          {image_url ? (
            <Image
              fill
              loading={imageLoading || 'lazy'}
              fetchPriority={fetchPriority || 'low'}
              src={image_url}
              alt={product_name}
              aspectRatio={width / height}
              sizes="
              (max-width: 768px) 50vw"
              crop="center"
              imageClassName={clsx(
                'product-item-image',
                isVertical &&
                  'absolute inset-0 m-auto max-h-[144px] !max-w-[179px] object-contain lg:h-auto lg:max-h-[200px] lg:!max-w-[296px]'
              )}
              className={clsx(
                'max-h-[16.75rem] md:max-h-[28.125rem]',
                isVertical &&
                  'h-full max-h-[144px] w-full max-w-[179px] lg:max-h-full lg:max-w-full xl:h-[200px] xl:w-[296px]'
              )}
            />
          ) : (
            <Image
              className="h-full"
              src="/noimage.jpg"
              fill
              aspectRatio={width / height}
              sizes="100vw"
              alt={product_name}
              imageClassName="object-cover"
            />
          )}
        </Link>
        <div
          className={clsx('absolute left-2 top-2 mb-4 flex gap-x-4 lg:mb-8', {
            hidden: !showBadges
          })}
        >
          {badges?.map((badge, index) => {
            const imageUrl = badge?.kwargs?.value?.icon?.url;
            if (!imageUrl) return null;
            return (
              <div
                key={index}
                className="w-12 flex-col items-center justify-center lg:w-14"
              >
                <Image
                  src={imageUrl}
                  fill
                  aspectRatio={80 / 80}
                  sizes="80"
                  alt={product_name}
                  imageClassName="object-cover"
                />
              </div>
            );
          })}
        </div>

        {colorCount > 1 && (
          <div className="absolute bottom-2 right-2 flex h-[26px] items-center border border-lightBlue-500 bg-gray-50 px-[8.5px] text-xs leading-[18px] text-primary lg:h-9 lg:px-2 lg:text-sm lg:leading-5">
            {colorCount} {t('product.color_count')}
          </div>
        )}
        {!isVertical && <FavButton className="absolute right-4 top-4" />}
      </div>
      <div
        className={clsx(
          'relative flex flex-col justify-between px-1 md:pl-0',
          contentClassName
        )}
      >
        {quickShopOpen && (
          <div
            className={clsx(
              variantCount > 3 && 'max-h-[200%] overflow-y-auto',
              'absolute bottom-[45px] z-[5] hidden h-auto w-full bg-white py-3 lg:block'
            )}
          >
            {colorVariants?.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.color')}
                </span>
                <div className="flex flex-wrap gap-3">
                  {colorVariants.map(
                    (color, index) =>
                      color.product.productimage_set[0]?.image && (
                        <button
                          className={clsx(
                            'relative cursor-pointer border text-sm',
                            !color.is_selectable &&
                              '!cursor-not-allowed opacity-50 before:absolute before:left-[-14px] before:top-1/2 before:z-[1] before:block before:h-[1px] before:w-[162%] before:rotate-[-52deg] before:bg-black/80 before:content-[""]',
                            color.is_selected
                              ? '!border-black lg:!border-black'
                              : 'border-lightBlue',
                            color.product.productimage_set[0]?.image
                              ? 'h-[64px] w-[48px] p-0'
                              : 'px-3 py-2'
                          )}
                          key={index}
                          onClick={() => {
                            if (!color.is_selectable) return;
                            selectAttribute(
                              'integration_renk',
                              color.product.attributes['integration_renk']
                            );
                            setProduct(color.product);
                          }}
                          title={
                            color?.product?.attributes_kwargs?.integration_renk
                              ?.label
                          }
                        >
                          {color.product.productimage_set[0]?.image ? (
                            <Image
                              src={color.product.productimage_set[0]?.image}
                              alt={color.label}
                              width={48}
                              height={64}
                              className="flex !h-full w-auto justify-center"
                            />
                          ) : (
                            color.label
                          )}
                        </button>
                      )
                  )}
                </div>
              </div>
            )}

            {sizeVariants?.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.size')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {sizeVariants.map((size, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !size.is_selectable && '!cursor-not-allowed opacity-50',
                        size.is_selected
                          ? '!border-black lg:!border-black'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!size.is_selectable) return;
                        selectAttribute(
                          'integration_hacim',
                          size.product.attributes['integration_hacim']
                        );
                        setProduct(size.product);
                      }}
                      disabled={!size.is_selectable}
                    >
                      {size.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {lengthVariants?.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.length')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {lengthVariants.map((length, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !length.is_selectable &&
                          '!cursor-not-allowed opacity-50',
                        length.is_selected
                          ? '!border-black lg:!border-black'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!length.is_selectable) return;
                        selectAttribute(
                          'integration_uzunluk',
                          length.product.attributes['integration_uzunluk']
                        );
                        setProduct(length.product);
                      }}
                      disabled={!length.is_selectable}
                    >
                      {length.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {heightVariants?.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.height')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {heightVariants.map((height, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !height.is_selectable &&
                          '!cursor-not-allowed opacity-50',
                        height.is_selected
                          ? '!border-black lg:!border-black'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!height.is_selectable) return;
                        selectAttribute(
                          'integration_yukseklik',
                          height.product.attributes['integration_yukseklik']
                        );
                        setProduct(height.product);
                      }}
                      disabled={!height.is_selectable}
                    >
                      {height.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {widthVariants?.length > 0 && (
              <div className="mb-4 flex flex-col gap-[4px] lg:mb-2">
                <span className="text-sm text-gray-600">
                  {t('product.quick_shop.width')}
                </span>
                <div className="flex flex-wrap gap-3 gap-y-2">
                  {widthVariants.map((width, index) => (
                    <button
                      className={clsx(
                        'relative cursor-pointer border border-lightBlue px-3 py-2',
                        !width.is_selectable &&
                          '!cursor-not-allowed opacity-50',
                        width.is_selected
                          ? '!border-black lg:!border-black'
                          : 'border-lightBlue'
                      )}
                      key={index}
                      onClick={() => {
                        if (!width.is_selectable) return;
                        selectAttribute(
                          'integration_genislik',
                          width.product.attributes['integration_genislik']
                        );
                        setProduct(width.product);
                      }}
                      disabled={!width.is_selectable}
                    >
                      {width.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {removeGiftList && !product.in_stock && (
              <span className="text-xs font-medium leading-[1.5] text-error-400">
                {t('product.stock_alert.warning')}
              </span>
            )}
          </div>
        )}
        <div
          className={clsx('product-badge mb-2 flex h-8 gap-2', {
            hidden: !showBadges
          })}
        >
          {badges?.map(
            (badge, index) =>
              badge?.value?.attribute === 'online_ozel' && (
                <div
                  key={index}
                  className="flex items-center gap-2 bg-secondary-50 py-1 pl-4 pr-3 text-sm font-medium text-secondary-400"
                >
                  {badge?.value?.label}
                </div>
              )
          )}
          {product?.attributes?.integration_urun_grup_text !== 'BUTIK' &&
          stock <= 2 ? (
            <div className="product-badge mb-2  hidden h-8 gap-2 md:flex">
              <span className=" bg-error-300 p-[6px] text-xs text-error  md:text-sm">
                {t('product.running_out')}
              </span>
            </div>
          ) : null}
        </div>
        <div
          className={clsx('min-h-11', {
            'flex justify-between gap-2': removeGiftList,
            'h-auto !min-h-0': isVertical
          })}
        >
          <Link
            href={absolute_url}
            className={clsx(
              'line-clamp-2 text-sm font-medium md:text-base',
              isVertical && '!line-clamp-1'
            )}
            data-testid={`${product_name}-${index}`}
            onClick={() => pushProductClicked(product)}
          >
            <h3
              className={clsx(
                isVertical && '!line-clamp-1 text-base font-normal'
              )}
            >
              {product_name}
            </h3>
          </Link>

          {removeGiftList && (
            <div
              className="mr-1 flex cursor-pointer justify-end"
              onClick={() => {
                removeFromGiftList(removeGiftList);
              }}
            >
              <Icon name="bin" size={28} />
            </div>
          )}
        </div>

        <div
          className={clsx(
            'relative flex h-12 cursor-pointer flex-wrap items-end justify-between text-base font-semibold md:text-xl',
            isVertical && '!h-10 lg:!h-auto'
          )}
          onClick={() => handleProductClick()}
        >
          <div className="flex flex-col justify-between">
            {parseFloat(retail_price) > parseFloat(price) && (
              <div className="mb-1 flex items-center text-xl md:m-0 md:text-base lg:text-xl">
                <Icon
                  name={'down-small-full'}
                  className={clsx('ml-[-6px] text-error')}
                />
                <span className="mr-1 text-xs text-error underline md:mr-2 lg:mr-3">
                  %{discount}{' '}
                </span>
                <span className="text-xs text-gray-500 line-through md:text-sm">
                  <Price
                    value={retail_price}
                    className="mr-1 font-normal line-through md:mr-3"
                  />
                </span>
              </div>
            )}
            <Price
              value={price}
              data-testid="product-price"
              className="text-base lg:text-xl"
            />
          </div>
          {loading ? (
            <div className="mr-2 flex h-8 w-8 items-center justify-start gap-[14px]  rounded-full bg-primary px-2 py-1 text-sm text-white md:h-10 md:w-[135px] lg:mr-0 lg:rounded-none">
              <LoaderSpinner className="h-4 w-4 border-white border-b-transparent lg:h-6 lg:w-6" />
            </div>
          ) : quickShopOpen && !isMobile ? (
            <>
              {!selectedProduct.in_stock ? (
                <Button
                  className={clsx('px-2 py-1 text-sm text-white md:h-10 ', '')}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowStockAlertModal(true);
                  }}
                >
                  {t('product.add_stock_alert')}
                </Button>
              ) : (
                <div
                  className={clsx(
                    'flex !items-center justify-start gap-[14px] bg-primary px-2 py-1 text-sm text-white md:h-10 md:w-[135px]',
                    isVertical && '!mr-0'
                  )}
                >
                  <Icon name="plus" />
                  <span>{t('product.quick_shop.add_to_cart')}</span>
                </div>
              )}
            </>
          ) : (
            <Icon
              name="plus"
              className={clsx(
                'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-primary text-sm font-semibold text-white md:text-base lg:mr-10 lg:h-10 lg:w-10',
                isVertical && '!mr-2 h-8 w-8 lg:!mr-6 lg:h-10 lg:w-10'
              )}
            />
          )}
          {productError && (
            <span className="absolute -bottom-1  flex min-h-11 w-full items-center bg-white text-sm text-error">
              {productError}
            </span>
          )}
        </div>
        {errorMessage && (
          <div className="py-2 text-xs text-error-400">{errorMessage}</div>
        )}
      </div>
      <ProductStockAlertModal
        open={showStockAlertModal}
        setOpen={setShowStockAlertModal}
        productPk={selectedProduct?.pk}
      />

      <BottomSheet
        open={showBottomSheet}
        portalId="add-product-to-basket-success"
        setOpen={setShowBottomSheet}
        title={t('product.add_basket.title')}
        icon="bag"
        titleIconSize={32}
        headerClassName="border-b border-lightBlue"
        closeButtonSize={32}
      >
        <div className="flex gap-4 px-4 py-6">
          <Image
            src={selectedProduct?.productimage_set?.[0]?.image}
            alt={selectedProduct?.name}
            width={80}
            height={120}
            imageClassName="w-full h-full max-h-[120px] max-w-[80px] object-cover"
            aspectRatio={2 / 3}
          />
          <div className="flex flex-col justify-between">
            <span className="mt-4 text-sm font-normal text-primary">
              {selectedProduct.name}
            </span>
            <div className="mb-4 flex items-center gap-2">
              <span className="text-xs font-normal text-gray-600">
                {selectedProduct?.attributes?.filterable_renk}
              </span>
              <span className="size-1 rounded-full bg-gray-600"></span>
              <span className="text-xs font-normal text-gray-600">
                {selectedProduct?.attributes?.integration_cap}
              </span>
            </div>
          </div>
        </div>

        <div className="mb-4 w-full px-4">
          <Button
            onClick={() => {
              router.push(`${ROUTES.BASKET}`);
              setShowBottomSheet(false);
            }}
            className="h-12 w-full px-10 font-semibold"
          >
            {t('product.add_basket.link')}
          </Button>
        </div>
      </BottomSheet>
    </div>
  );
};
